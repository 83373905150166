<template>
  <div
    :class="
      clean
        ? ['flex-row', 'common-card', 'card']
        : ['flex-row', 'card common-card', 'three-d-shadow-mid']
    "
    :style="clean ? { background: 'none' } : {}"
  >
    <div
      class="main-content flex-column no-expand"
      style="justify-content: center"
    >
      <div class="res-name">
        <div class="van-ellipsis">
          {{ res.name }}
        </div>
      </div>
      <!--      <div class="table-name">
        <div>
          <span>桌位：{{ res.pos }}</span>
        </div>
      </div>-->
      <!--      <div v-if="footer" class="avatar flex-row" style="align-items: center">
        <van-image
          round
          width="1rem"
          height="1rem"
          fit="cover"
          :src="user.avatar"
        />
        <div style="font-size: 0.6rem; padding-left: 0.3rem">
          <span>{{ user.nickname }}, 欢迎光临！</span>
        </div>
      </div>-->
    </div>
    <div
      class="main-content flex-column no-expand"
      style="justify-content: space-between; align-items: flex-end"
    >
      <div class="res-avatar">
        <van-image
          round
          width="2.3rem"
          height="2.3rem"
          fit="cover"
          :src="res.pic"
        />
      </div>
      <!--      <div
        v-if="res.printNo && footer"
        style="
          font-size: 0.6rem;
          height: 1rem;
          display: flex;
          align-items: center;
        "
      >
        <van-button
          style="height: 1rem"
          round
          type="primary"
          size="mini"
          @click="show = true"
          >查看取餐号</van-button
        >
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Image as VanImage } from "vant";
export default {
  name: "tableCard",
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
    clean: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VanImage,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      res: (state) => state.base.currentRestaurant,
      user: (state) => state.base.currentUser,
    }),
  },
};
</script>

<style lang="less" scoped>
.card {
  .avatar {
    margin-top: auto;
  }
  .res-name {
    font-size: 1rem;
    font-weight: bold;
  }
  .table-name {
    color: #333333;
    margin-top: auto;
    font-size: 0.6rem;
  }
  .jump-link {
    height: 1rem;
    font-size: 0.4rem;
    a {
      display: block;
      color: #1989fa;
    }
  }
  justify-content: space-between;
  align-items: stretch;
}
</style>
