<template>
  <div class="full no-expand" style="background-color: #fff">
    <shop-bar />
    <div
      class="full"
      style="transition: all 0.4s ease-in-out"
      :style="{
        transform: showTop ? 'translateY(0)' : `translateY(-${topHeight}px)`,
        transitionDelay: showTop ? '.3s' : '0s',
      }"
    >
      <div
        class="top-block"
        ref="top"
        :style="{
          marginBottom: showTop ? '0rem' : '1.2rem',
          backgroundImage:'url('+ (merchant.headImg) +')',
        }"
      >
        <div>
          <div class="top-blank" style="width: 100%; height: 80px"></div>
          <div class="top-merchant">
            <table-card clean :footer="false"></table-card>
            <div class="top-blank" style="width: 100%; height: 0.6rem"></div>
            <div class="top-rank">
              <div
                style="
                  font-size: 0.6rem;
                  color: #f57a29;
                  display: flex;
                  align-items: flex-end;
                "
              >
                <img
                  class="fire-icon"
                  src="../assets/image/fire.png"
                  alt="."
                /><span style="color: #000">热销榜</span>
              </div>
              <div class="top-rank-list" v-if="topGoods.length > 0">
                <div
                  v-for="item in topGoods"
                  class="top-rank-item"
                  :key="item.target.id"
                >
                  <van-image
                    round
                    width="2.5rem"
                    height="2.5rem"
                    fit="cover"
                    style="box-shadow: 0 1px 2px 0 #b5b5b5"
                    @click="
                      item.target.stock > 0 &&
                        showPopupDetail(item.pos, item.target)
                    "
                    :src="item.target.img_url"
                  ></van-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="opt-bar"
          :style="{
            bottom: optPosition,
            transitionDelay: showTop ? '0s' : '.6s',
            background: showTop ? '#fff' : '#f2eae8',
          }"
        >
          <img src="../assets/image/food.png" alt="." style="width: 0.8rem" />
<!--          <div>{{ merchant.pos }}·{{ num }}位&nbsp;&nbsp;&nbsp;<a style="color: #F76045" v-if="merchant.isSingle">查看取餐码</a></div>-->
          <div>{{ merchant.pos }}·{{ num }}位</div>
          <div style="width: 2.3rem; text-align: center; margin-left: auto">
            <van-icon
              v-if="orderUnDone.length > 0 || orderDone.length > 0"
              class="common-color"
              name="wap-home"
              size="0.8rem"
              @click="$router.push('/home')"
            />
          </div>
        </div>
      </div>
      <div style="height: 100%; background-color: #fff" class="no-expand">
        <div class="flex-row full" style="position: relative">
          <div class="navigator no-shrink" style="overflow: hidden">
            <scroll ref="scrollSide">
              <sidebar
                v-model="activeKey"
                @change="sidebarChange"
                style="height: 100%; background: #f6f6f6"
              >
                <sidebar-item
                  v-for="item in menu"
                  :key="item.id"
                  :title="item.name"
                />
              </sidebar>
            </scroll>
          </div>
          <div class="list" style="overflow: hidden; flex: 1">
            <scroll
              ref="scroll"
              @scrollEnd="noScroll = false"
              @scroll="indexChange"
            >
              <div style="padding: 0.4rem 0">
                <template v-for="(item, index) in menu">
                  <div
                    :ref="'anchor'"
                    :class="
                      index === menu.length - 1
                        ? ['anchor', 'last']
                        : ['anchor']
                    "
                    :key="item.id"
                    :style="{ color: item.type === 'top' ? 'red' : 'inherit' }"
                  >
                    <div class="flex-row">
                      <div class="flex-row" style="align-items: center">
                        <svg
                          width="95"
                          height="4"
                          viewBox="0 0 95 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 2L95 0V4L0 2.5V2Z" fill="#F76045" />
                        </svg>
                      </div>
                      <div
                        style="flex: 1; text-align: center; font-size: 0.6rem"
                      >
                        {{ item.name }}
                      </div>
                      <div class="flex-row" style="align-items: center">
                        <svg
                          width="95"
                          height="4"
                          viewBox="0 0 95 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M95 2L0 3.99999L3.4969e-07 3.94865e-08L95 1.50001L95 2Z"
                            fill="#F76045"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(_i, _index) in item.children"
                    style="border-radius: 0.3rem; padding: 0.3rem"
                    :style="_index === 0 ? { marginTop: 0 } : {}"
                    :key="index + _i.id"
                  >
                    <div style="border-bottom: 1px solid #dddddd">
                      <div
                        style="
                          display: flex;
                          border-radius: 8px;
                          overflow: hidden;
                        "
                      >
                        <van-image
                          @click="
                            _i.stock > 0 && showPopupDetail([index, _index], _i)
                          "
                          width="100%"
                          height="5rem"
                          fit="cover"
                          :src="_i.img_url"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                      </div>
                      <div
                        :class="{
                          'disabled-stepper': !_i.total || _i.total <= 0,
                          'flex-row': true,
                        }"
                        style="
                          justify-content: space-between;
                          align-items: flex-end;
                          padding: 0.1rem 0 0.3rem 0;
                        "
                      >
                        <div class="storage">
                          <div class="food-name van-ellipsis">
                            {{ _i.name }}
                          </div>
                          <div class="price">
                            <template v-if="_i.member_price">
                              <span>¥&nbsp;{{ _i.member_price / 100 }}/份</span>
                              <span
                                class="lined"
                                style="text-decoration: line-through"
                                >¥&nbsp;{{ _i.price / 100 }}/份</span
                              >
                            </template>
                            <template v-else>
                              <span>¥&nbsp;{{ _i.price / 100 }}/份</span>
                            </template>
                          </div>
                        </div>
                        <tag v-if="merchant.isClose" plain type="danger"
                          >已打烊</tag
                        >
                        <stepper
                          v-else-if="_i.stock > 0"
                          theme="round"
                          min="0"
                          button-size="22"
                          disable-input
                          async-change
                          style="flex-shrink: 0"
                          :disabled="_i.loading"
                          :value="_i.total || 0"
                          @plus="changeGoodNum([index, _index], _i, 'plus')"
                          @minus="changeGoodNum([index, _index], _i, 'minus')"
                        />
                        <tag v-else plain type="danger">已售罄</tag>
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="end-blank"
                  ref="blank"
                  style="height: 0; overflow: hidden"
                >
                  <div style="padding: 0.4rem 0">
                    <div class="empty-text">已经到底了</div>
                  </div>
                </div>
              </div>
            </scroll>
          </div>
        </div>
      </div>
    </div>
    <van-image-preview v-model="previewShow" :images="previewImages">
    </van-image-preview>
    <popup
      :lock-scroll="false"
      class="my-popup"
      round
      v-model="showDetail"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '70%' }"
      @opened="$refs.optScroll && $refs.optScroll.refresh()"
    >
      <div
        v-if="currentGood.id"
        class="flex-column no-expand full"
        style="box-sizing: border-box; padding: 0.8rem 0.3rem"
      >
        <div class="flex-row" style="align-items: flex-end">
          <div class="good-detail-pic">
            <van-image
              width="5rem"
              height="5rem"
              fit="cover"
              :src="currentGood.img_url"
              @click="previewShow = true"
            />
          </div>
          <div class="good-detail-info flex-column" style="padding: 0 0.4rem">
            <div class="good-detail-price">
              <span style="font-size: 0.8rem; padding-right: 0.2rem">¥</span>
              <span style="font-size: 1.4rem">{{
                (currentGood.member_price
                  ? currentGood.member_price
                  : currentGood.price) / 100
              }}</span>
              <span
                style="
                  font-size: 0.7rem;
                  color: #7e8c8d;
                  margin-left: 0.2rem;
                  text-decoration: line-through;
                "
                v-if="currentGood.member_price"
                >¥{{ currentGood.price / 100 }}</span
              >
            </div>
            <div style="color: #747777; font-size: 0.5rem; padding: 0.4rem 0">
              剩余{{ currentGood.stock }}份
            </div>
            <div style="color: #747777; font-size: 0.5rem">
              {{ currentGood.introduce }}
            </div>
          </div>
        </div>
        <van-divider />
        <div style="font-size: 0.8rem">
          {{ currentGood.name }}
        </div>
        <van-divider />
        <div v-if="currentGood.options" class="good-detail-options no-expand">
          <scroll ref="optScroll">
            <div>
              <div
                v-for="(item, index) in currentGood.options"
                class="good-detail-opt"
                :key="index"
              >
                <div style="font-size: 0.7rem; color: #747777">
                  {{ item.name }}
                </div>
                <div class="flex-row">
                  <div
                    style="padding: 0.4rem 0.2rem"
                    v-for="(opt, _index) in item.children"
                    :key="_index"
                  >
                    <tag
                      color="#F76045"
                      :plain="item.selected !== opt.id"
                      size="large"
                      @click="selectTag(item, opt.id)"
                      >{{ opt.name }}</tag
                    >
                  </div>
                </div>
              </div>
            </div>
          </scroll>
        </div>
        <div class="good-detail-operation flex-column no-shrink">
          <div
            class="flex-row"
            style="justify-content: space-between; padding-bottom: 0.3rem"
          >
            <span style="font-size: 0.8rem; color: #353737">购买数量</span>
            <tag v-if="merchant.isClose" plain type="danger">已打烊</tag>
            <stepper
              v-else
              v-model="currentGood.num"
              theme="round"
              min="0"
              :disabled="merchant.isClose"
              button-size="26"
              disable-input
            />
          </div>
          <van-button
            round
            :loading="loading"
            loading-text="增加至购物车中..."
            :disabled="currentGood.num <= 0"
            type="primary"
            style="width: 100%"
            class="three-d-shadow-dark"
            @click="
              goToCart({
                good: currentGood,
                value: currentGood.num,
                type: 'plus',
              })
            "
          >
            确定
          </van-button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import {
  Button as VanButton,
  Divider as VanDivider,
  Image as VanImage,
  Icon as VanIcon,
  ImagePreview,
  Loading as VanLoading,
  Popup,
  Sidebar,
  SidebarItem,
  Stepper,
  Tag,
} from "vant";
import Scroll from "@/components/scroll";
import { mapState } from "vuex";
import { getTopGoods } from "@/api/base";
import { search } from "@/utils/common";
import ShopBar from "@/components/shopBar";
import TableCard from "@/components/tableCard";

export default {
  name: "Shopping",
  components: {
    TableCard,
    ShopBar,
    Sidebar,
    SidebarItem,
    VanLoading,
    Scroll,
    Stepper,
    Tag,
    Popup,
    VanIcon,
    VanImage,
    VanDivider,
    VanButton,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      activeKey: null,
      anchors: [],
      noScroll: false,
      showDetail: false,
      previewShow: false,
      currentGood: {},
      currentCategory: {},
      loading: false,
      showTop: true,
      topHeight: 0,
      optPosition: "-1rem",
      topGoods: [],
    };
  },
  computed: {
    previewImages() {
      if (this.currentGood.img_url) {
        return [this.currentGood.img_url];
      } else return [];
    },
    ...mapState({
      orderDone: (state) => state.base.orderDone,
      orderUnDone: (state) => state.base.orderUnDone,
      menu: (state) => state.base.totalGoods,
      merId: (state) => state.base.merchantId,
      num: (state) => state.base.eaterNum,
      merchant: (state) => state.base.currentRestaurant,
    }),
  },
  watch: {
    menu: {
      handler(val) {
        if (val.length > 0) {
          this.$nextTick(() => {
            const anchors = this.$refs.anchor;
            this.$refs.scroll.refresh();
            this.$refs.scrollSide.refresh();
            this.anchors = anchors.map((val) => val.offsetTop);
          });
        }
      },
      immediate: true,
    },
    showTop: {
      handler(val) {
        const target = this.$refs.top;
        if (!val) {
          this.topHeight = target.clientHeight;
          this.optPosition = "-1rem";
        } else if (target) {
          this.optPosition =
            target.querySelector(".top-rank").clientHeight + "px";
        }
      },
      immediate: true,
    },
    $route: {
      handler(to) {
        if (to.name === "Shopping") {
          this.getTop();
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    indexChange(pos) {
      !this.noScroll && (this.showTop = pos.y > 0);
      this.noScroll = true;
      const y = -1 * pos.y;
      let _index = 0;
      for (const index in this.anchors) {
        if (y >= this.anchors[index]) {
          _index = index;
        } else {
          break;
        }
      }
      this.activeKey = _index;
    },
    sidebarChange(index) {
      if (!this.noScroll) {
        this.$refs.scroll.scrollTo(0, -1 * this.anchors[index]);
      }
    },
    showPopupDetail(pos, item) {
      this.currentGood = item;
      this.$set(item, "pos", pos);
      item.total === undefined ? this.$set(item, "total", 0) : null;
      item.userStock === undefined ? this.$set(item, "userStock", []) : null;
      if (!item.num) {
        this.$set(item, "num", 1);
      } else {
        item.num = 1;
      }
      //初始化选项
      const { options } = this.currentGood;
      if (options && options.length && options.length > 0) {
        options.forEach((val) => {
          if (!val.selected) {
            val.required
              ? this.$set(val, "selected", val.children[0].id)
              : this.$set(val, "selected", -1);
          }
        });
      }
      this.showDetail = true;
    },
    selectTag(opt, id) {
      if (opt.required) {
        opt.selected = id;
      } else {
        opt.selected
          ? opt.selected === id
            ? (opt.selected = -1)
            : (opt.selected = id)
          : (opt.selected = id);
      }
    },
    changeGoodNum(pos, item, type) {
      item.total === undefined ? this.$set(item, "total", 0) : null;
      item.userStock === undefined ? this.$set(item, "userStock", []) : null;
      this.$set(item, "pos", pos);
      if (item.options && item.options.length > 0) {
        this.showPopupDetail(item);
      } else {
        this.goToCart({ good: item, type });
      }
    },
    goToCart(data) {
      data.good.loading = true;
      this.$store
        .dispatch("cart/checkout", data)
        .then(() => {
          data.good.loading = false;
          this.showDetail = false;
        })
        .catch(() => {
          data.good.loading = false;
          this.$toast.fail("加菜失败");
        });
    },
    getTop() {
      getTopGoods(this.merId)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.topGoods = res.data.map((value) =>
              search(value.id, this.menu)
            );
          } else {
            this.topGoods = [];
          }
          this.showTop &&
            this.$nextTick(() => {
              const target = this.$refs.top;
              const h = target.firstElementChild.clientHeight;
              target.style.height = `${h}px`;
              this.optPosition =
                target.querySelector(".top-rank").clientHeight + "px";
            });
        })
        .catch(() => {
          this.$toast.fail("获取排行榜失败，请稍后再试");
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        // 计算高度
        const container = vm.$refs.scroll.$el;
        const blank = vm.$refs.blank;
        const h = container.clientHeight;
        const list = container.firstElementChild.querySelectorAll(
          ".anchor.last + div:not(.end-blank)"
        );
        let new_height = 0;
        if (list) {
          let total = 0;
          list.forEach((value) => {
            total += value.clientHeight;
          });
          total < h && (new_height = h - total);
        } else {
          new_height = h;
        }
        blank.style.height = `${new_height}px`;
        vm.$refs.scroll.refresh();
        vm.$refs.scrollSide.refresh();
      });
    });
  },
};
</script>

<style lang="less" scoped>
.navigator {
  /deep/.van-sidebar {
    width: auto;
  }
}
.fire-icon {
  height: 0.6rem;
  width: auto;
}
.top-block {
  /*background-image: url("../assets/image/top-back.png");*/
  /*background-size: auto, 100px;*/
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  transition: all 0.6s ease-in-out;
  height: auto;
  position: relative;
  .opt-bar {
    font-size: 0.6rem;
    display: flex;
    padding: 0 0.6rem;
    box-sizing: border-box;
    height: 1rem;
    position: absolute;
    align-items: center;
    transition: all 0.3s ease-in-out;
    width: 100%;
    left: 0;
  }
  .top-merchant {
    background: #fff;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    .top-rank {
      .fire {
        font-size: 0.6rem;
        margin-right: 5px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to top, #f44336, #ff6d00, #ff9800);
      }
      padding: 0.4rem 0.6rem;
      .top-rank-list {
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        .top-rank-item {
          height: 2.5rem;
          width: 2.5rem;
          box-sizing: content-box;
          margin: 1.2rem 0.8rem 0 0;
          position: relative;
          &:nth-child(1) {
            background-clip: border-box;
            padding: 3px;
            border-radius: 50%;
            background-image: linear-gradient(#cd8905, #f6ce81);
            &:before {
              content: "";
              position: absolute;
              top: -30px;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("../assets/image/gold.png") center no-repeat;
            }
          }
          &:nth-child(2) {
            background-clip: border-box;
            padding: 3px;
            border-radius: 50%;
            background-image: linear-gradient(#b2b2b2, #d0d0d0);
            &:before {
              content: "";
              position: absolute;
              top: -30px;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("../assets/image/silver.png") center no-repeat;
            }
          }
          &:nth-child(3) {
            background-clip: border-box;
            padding: 3px;
            border-radius: 50%;
            background-image: linear-gradient(#b87333, #dfa36b);
            &:before {
              content: "";
              position: absolute;
              top: -30px;
              left: 0;
              width: 100%;
              height: 100%;
              background: url("../assets/image/brone.png") center no-repeat;
            }
          }
          &:last-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.list {
  padding: 0 0.6rem;
}
.anchor {
  z-index: 1;
  box-sizing: border-box;
  color: #323233;
  font-weight: 500;
  font-size: 14px;
  /*line-height: 32px;*/
}
.my-popup {
  //height: 70% !important;
  overflow: hidden;
}
.good-detail-price {
  color: @primary-color;
}
.good-detail-operation {
  margin-top: auto;
  padding-top: 0.4rem;
  border-top: 1px solid #ebedf0;
}
.empty-text {
  font-size: 0.6rem;
  color: #b8b8b8;
  display: flex;
  align-items: center;
  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 3px;
    background: #dddddd;
  }
}
.disabled-stepper {
  /deep/.van-stepper__minus,
  /deep/.van-stepper__input:read-only {
    visibility: hidden;
  }
}
</style>
