<template>
  <div style="position: absolute; left: 0; bottom: 40px; z-index: 1000">
    <div class="bar">
      <div class="bar-content flex-row">
        <van-popover
          v-model="showPopover"
          placement="top-start"
          trigger="click"
          @opened="$refs.scroll && $refs.scroll.refresh()"
          overlay
        >
          <div
            class="flex-column"
            style="
              width: calc(100vw - 2rem);
              padding: 0.8rem;
              box-sizing: border-box;
              font-size: 0.6rem;
            "
          >
            <div style="display: flex; justify-content: space-between">
              <template v-if="getTotalCountAndPrice.count > 0">
                <div>已选 {{ getTotalCountAndPrice.count }} 份</div>
                <div style="font-size: 0.8rem" @click="clearCart">
                  <van-icon name="delete-o" />
                </div>
              </template>
              <div v-else>购物车空空如也～</div>
            </div>
            <div v-if="getTotalCountAndPrice.count > 0" class="divider"></div>
            <div style="height: 6rem">
              <scroll ref="scroll">
                <div>
                  <div
                    v-for="item in goods"
                    :key="item.id"
                    class="flex-row"
                    style="padding-top: 0.6rem; align-items: center"
                  >
                    <div class="van-ellipsis" style="flex: 1">
                      {{ item.name }}
                    </div>
                    <div
                      class="common-color"
                      style="width: 1.5rem; padding-right: 10px"
                    >
                      ¥{{
                        ((item.member_price || item.price) / 100).toFixed(2)
                      }}
                    </div>
                    <div style="flex-shrink: 0">
                      <stepper
                        theme="round"
                        min="0"
                        button-size="22"
                        disable-input
                        async-change
                        :disabled="item.loading"
                        :value="item.total || 0"
                        @plus="changeGoodNum(item, 'plus')"
                        @minus="changeGoodNum(item, 'minus')"
                      />
                    </div>
                  </div>
                </div>
              </scroll>
            </div>
          </div>
          <template #reference>
            <van-badge :content="getTotalCountAndPrice.count">
              <div class="bar-icon">
                <van-icon size="44px" name="cart-circle-o" color="white" />
              </div>
            </van-badge>
          </template>
        </van-popover>

        <div class="bar-desc">
          <template v-if="this.getTotalCountAndPrice.count <= 0">
            购物车空空如也～～
          </template>
          <template v-else>
            总价：<span>¥{{ this.getTotalCountAndPrice.price }}</span>
          </template>
        </div>
        <van-button style="height: auto" round type="primary" to="cart"
          >选好了</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  Icon as VanIcon,
  Button as VanButton,
  Badge as VanBadge,
  Popover as VanPopover,
  Stepper,
} from "vant";
import { mapGetters, mapState } from "vuex";
import Scroll from "@/components/scroll";
export default {
  name: "shopBar",
  components: {
    Scroll,
    VanIcon,
    VanBadge,
    VanButton,
    VanPopover,
    Stepper,
  },
  data() {
    return {
      showPopover: false,
    };
  },
  watch: {
    goods(now) {
      if (now.length <= 0) {
        this.showPopover = false;
      }
    },
  },
  methods: {
    changeGoodNum(item, type) {
      item.total === undefined ? this.$set(item, "total", 0) : null;
      item.userStock === undefined ? this.$set(item, "userStock", []) : null;
      this.goToCart({ good: item, type });
    },
    clearCart() {
      this.$store.dispatch("cart/checkout", { type: "clear" }).then(() => {
        this.showPopover = false;
      });
    },
    goToCart(data) {
      data.good.loading = true;
      this.$store
        .dispatch("cart/checkout", data)
        .then(() => {
          data.good.loading = false;
          this.showDetail = false;
        })
        .catch((err) => {
          console.log(err)
          data.good.loading = false;
          this.$toast.fail("加菜失败");
        });
    },
  },
  computed: {
    ...mapState({
      goods: (state) => state.cart.goods,
    }),
    ...mapGetters("cart", ["getTotalCountAndPrice"]),
  },
};
</script>

<style lang="less" scoped>
.divider {
  padding-top: 0.6rem;
  border-bottom: 1px solid #e5e5e5;
}
.bar {
  width: 100vw;
  padding: 0 1rem;
  box-sizing: border-box;
  .bar-content {
    background-color: #5b6363;
    border-radius: 44px;
    /deep/.van-badge--fixed {
      top: 4px;
      right: 4px;
    }
    .bar-desc {
      flex: 1;
      font-size: 0.6rem;
      color: #b5c0c1;
      display: flex;
      align-items: center;
      padding-left: 0.3rem;
      span {
        color: @primary-color;
        font-size: 0.8rem;
      }
    }
    .bar-icon {
      background-color: @primary-color;
      border-radius: 50%;
    }
  }
}
</style>
